<template>
  <div>
    <bread-crumb :navigationArray="navigationLink"/>
    <div class="news-wrapper">
      <div class="white-container news" :class="{'news-wrapper__content' : getListView}">
        <news-filter class="news__filter"
                     :current-tab="currentTab"
                     @tab-changed="tabChanged"
                     @getData="getData($event)"/>

        <section v-show="showItem" class="news__header">

          <div class="news__main">
            <p class="title_categories"> {{ $store.getters.GET_LANG ? 'Главное' : 'Top news' }}</p>
            <tricolor-line />
            <news-main v-if="getImportantNews && getImportantNews.length" :card="getImportantNews[0]"/>
          </div>

          <div class="news__important" v-if="$store.getters.GET_LANG">
            <p class="title_categories"> {{ $store.getters.GET_LANG ? 'Анонсы' : 'Announcements' }} </p>
            <tricolor-line />
            <dynamic-news-card v-for="(announcement, index) in getPublicAnnouncements.slice(0, 3)"
                               :is-list-view="true"
                               :is-short-rubric="true"
                               @on-change-rubric="getData"
                               :content="announcement"
                               :key="`announcement${index}`"/>
          </div>
        </section>

        <section class="news__feed">
          <p v-show="showItem" class="title_categories">{{ $store.getters.GET_LANG ? 'Лента' : 'Feed' }}</p>
          <tricolor-line v-show="showItem" />
          <div class="news__feed-content" :class="{'news__content-list' : getListView}" >
            <dynamic-news-card v-for="(news, index) in getDataList"
                               :is-list-view="getListView"
                               :content="news"
                               @on-change-rubric="getData"
                               :currentTab="currentTab"
                               :key="`newsCard${index}`"/>
          </div>
        </section>

        <pagination v-if="currentTab !== 'All'"
                    class="news__pagination"
                    :per-page="$store.getters.getPaginationLimit"
                    :current="$store.getters.getPaginationCurrentPage"
                    :total="$store.getters.getPaginationTotal"
                    @page-changed="getPage"/>

        <div class="pagination__container" v-else-if="getDataList && getDataList.length">
          <p @click="setNearPage(false)" v-if="entitiesCurrentPage > 2">
            &lt;
          </p>
          <span v-if="entitiesCurrentPage > 2">
            ...
          </span>

          <p v-for="i in getAllEntities && getAllEntities.length"
             v-show="Math.abs(entitiesCurrentPage - i) < 3"
             :class="{current: i - 1 === entitiesCurrentPage}"
             :key="`pagination${i}`"
             @click="changeEntitiesPage(i - 1)">
            {{ i }}
          </p>

          <span v-if="entitiesCurrentPage < getAllEntities && getAllEntities.length - 3">
            ...
          </span>
          <p @click="setNearPage(true)" v-if="entitiesCurrentPage < getAllEntities && getAllEntities.length - 3">
            &gt;
          </p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'
import NewsFilter from '@/components/news/NewsFilter'
import TricolorLine from '@/components/TricolorLine'
import NewsMain from '@/components/news/NewsMain'
import DynamicNewsCard from '@/components/newsList/DynamicNewsCard'
import Pagination from '@/components/Pagination'

export default {
  name: 'NewsList',

  data () {
    return {
      entitiesCurrentPage: 0,

      currentPage: 1,
      carouselData: 0,
      showSlides: null,
      currentTab: this.$route.query.tab || 'All'
    }
  },

  watch: {
    '$route' (to, from) {
      this.currentTab = this.$route.query.tab
      this.currentPage = Number(this.$route.query.page) || 1
      if (!this.$route.query.page) {
        this.$store.commit('setPaginationOffset', 0)
        this.currentPage = 1
      }
      this.getData()
    },

    '$route.query.tab' () {
      this.currentTab = this.$route.query.tab
    },

    '$store.getters.GET_LANG' () {
      this.getData()
    }
  },

  beforeRouteLeave (to, from, next) {
    if (to.name === 'News' || to.name === 'Stream' || to.name === 'Media' || to.name === 'Announcements') {
      this.$store.commit('setRouteParams', { tab: this.$route.query?.tab, path: from.path, page: this.$route.query?.page || 1 })
      next()
    } else {
      this.$store.commit('setRouteParams', {})
      next()
    }
  },

  mounted () {
    this.currentTab = this.$route.query.tab
    this.entitiesCurrentPage = (Number(this.$route.query.page) - 1) || 0
    this.currentPage = Number(this.$route.query.page)
    this.$store.commit('setPagination', {
      offset: (this.$store.getters.getPaginationLimit * (this.currentPage - 1)) || 0
    })
    this.getData()
    document.title = 'Новости'
    this.$store.dispatch('setView', false)
    this.toggleDragWorking()
    window.addEventListener('resize', e => {
      this.toggleDragWorking()
    })

    this.$store.commit('setPublicNews', [])
    this.$store.commit('setPublicBlogs', [])
    this.$store.commit('setPublicAnnouncements', [])
    this.$store.commit('setPublicMedias', [])
    this.$store.commit('setPublicLiveStreams', [])
  },

  computed: {
    navigationLink () {
      return [
        {
          title: this.$store.getters.GET_LANG ? 'Главная' : 'Home',
          path: '/'
        },
        {
          title: this.$store.getters.GET_LANG ? 'Новости' : 'News'
        }
      ]
    },
    getDataList () {
      let data = []

      switch (this.currentTab) {
        case 'News' :
          data = this.getPublicNews
          break

        case 'Blogs' :
          data = this.getPublicBlogs
          break

        case 'Comment' :
          data = this.getAllNewsComments
          break

        case 'Announcements':
          data = this.getPublicAnnouncements
          break

        case 'LiveStream':
          data = this.getPublicLiveStreams
          break

        case 'Media':
          data = this.getPublicMedias
          break

        default:
          data = this.getAllEntities[this.entitiesCurrentPage]
          break
      }

      return data
    },

    getAllEntities () {
      return this.$store.getters.getAllEntities
    },

    getImportantNews () {
      return this.$store.getters.getImportantNews
    },

    getPublicNews () {
      return this.$store.getters.getPublicNews || []
    },

    getPublicAnnouncements () {
      return this.$store.getters.getPublicAnnouncements ? this.$store.getters.getPublicAnnouncements : []
    },

    getPublicLiveStreams () {
      return this.$store.getters.getPublicLiveStreams ? this.$store.getters.getPublicLiveStreams : []
    },

    getPublicMedias () {
      return this.$store.getters.getPublicMedias ? this.$store.getters.getPublicMedias : []
    },

    getPublicBlogs () {
      return this.$store.getters.getPublicBlogs ? this.$store.getters.getPublicBlogs : []
    },

    getImportant () {
      return this.$store.getters.getImportantPublicNews ? this.$store.getters.getImportantPublicNews : []
    },

    getAllNewsComments () {
      return this.$store.getters.getAllNewsComments ? this.$store.getters.getAllNewsComments : []
    },

    getListView () {
      return this.$store.getters.getListView
    },

    showItem () {
      return !this.getListView && this.currentTab === 'All'
    }
  },

  methods: {
    getData (isSearch) {
      let query = 'getPublicNews'
      switch (this.currentTab) {
        case 'All':
          query = 'getAllEntitiesPagination'
          this.$store.dispatch('getPublicAnnouncements', {
            headers: {
              Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
            }
          })
          break

        case 'News':
          query = 'getPublicNews'
          this.$store.commit('setPublicNews', [])
          if (!this.$store.getters.getFilterSystemTagList) {
            this.$store.commit('setFilterSystemTagList', 'all')
          }
          break

        case 'Comment':
          query = 'getAllNewsComments'
          break

        case 'Blogs':
          query = 'getPublicBlogs'
          break

        case 'LiveStream':
          query = 'getPublicLiveStreams'
          break

        case 'Media':
          query = 'getPublicMedias'
          break

        case 'Announcements':
          query = 'getPublicAnnouncements'
          break
      }
      this.$store.commit('setIsSearch', !!this.$store.getters.getFilters)

      this.$store.dispatch(query, {
        onlyWithTags: query === 'getPublicNews',
        headers: {
          Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
    },

    getPage (page) {
      this.$store.commit('setIsSearch', true)
      this.$store.commit('setPaginationOffset', (page - 1) * this.$store.getters.getPaginationLimit)
      this.getData()
      this.$router.push({ query: { ...this.$route.query, page } })
    },

    changeEntitiesPage (page) {
      this.entitiesCurrentPage = page
      this.$router.push({ query: { ...this.$route.query, page: page + 1 } })
    },

    setNearPage (direction) {
      if (!direction) {
        if (this.entitiesCurrentPage > 0) {
          this.entitiesCurrentPage--
          this.$router.push({ query: { ...this.$route.query, page: this.entitiesCurrentPage + 1 } })
        }
      } else {
        if (this.entitiesCurrentPage < this.getAllEntities?.length - 1) {
          this.entitiesCurrentPage++
          this.$router.push({ query: { ...this.$route.query, page: this.entitiesCurrentPage + 1 } })
        }
      }
    },

    tabChanged (tab) {
      console.log(tab)
      this.currentTab = tab
      this.$router.push({
        query: {
          tab: tab
        }
      })
      // tab !== 'All' && tab !== 'News' ? this.$store.dispatch('setView', true) : this.$store.dispatch('setView', false)
    },

    toggleDragWorking () {
      this.showSlides = window.innerWidth <= 420 ? 1 : window.innerWidth > 420 && window.innerWidth <= 780 ? 2 : null
    },

    changeSlider (num) {
      this.$refs.carousel.slideTo(num)
    },

    updateCarousel (payload) {
      this.carouselData = payload.currentSlide
    }
  },

  components: {
    Pagination,
    DynamicNewsCard,
    NewsMain,
    TricolorLine,
    BreadCrumb,
    NewsFilter
  }
}
</script>

<style scoped lang="scss">
  .pagination__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.1875rem;
      height: 2.1875rem;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.375rem;
      color: #246CB7;
      border: 1px solid #d2dde8;
      border-radius: 3px;
      margin: 0 0.375rem;
      transition: all .2s ease-in-out;
      cursor: pointer;

      &:hover {
        background: #d1e0ef;
        border: 1px solid #246CB7;
      }
    }

    .current {
      background: #d1e0ef;
    }
  }

  .news-wrapper {
    width: 90rem;
    margin: 0 auto;

    .news__feed-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      > * {
        margin-bottom: 2.5rem;
      }

      &:after {
        content: '';
        width: 26.5625rem;
      }
    }

    &__content {
      width: 61.4375rem;
      margin: 0;
    }
  }

  .news {
    .title_categories,
    .line_wrapper {
      margin-bottom: 1.25rem;
    }

    &__filter {
      margin-bottom: 2.5rem;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.5rem;
    }

    &__main {
      width: 55.375rem;
    }

    &__important {
      width: 26.625rem;

      ::v-deep > * {
        margin-bottom: 1rem;
      }

      div:not(:last-child) .card_important {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid #eee;
      }

    }

    &__feed-content {
      display: flex;
      flex-wrap: wrap;

      div {
        width: calc(33.333% - 2.125rem);
        margin: 0 1.06rem 2.125rem 1.06rem;

        cursor: pointer;
      }
      div.line {
        width: calc(50% - 2.125rem);
        margin: 0 1.06rem 2.125rem 1.06rem;
      }
    }
    &__content-list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin: 0;

      div {
        width: 100%;
        margin: 1.25rem 0;
        background: #FFFFFF;
        border: 1px solid #EBF3FF;
      }
    }

  }

  @media screen and (max-width: 768px){
    .news-wrapper {
      width: 100%;

      &__content {
        width: 100%;
      }
      .news__feed-content {
        justify-content: center;
      }
    }

    .news {
      &__main,
      &__important {
        width: 100%;
      }

      &__header {
        flex-direction: column;
      }

      &__important-container {
          display: none;
      }
      &__important {
        margin-top: 2.5rem;

        .news-banner {
          width: 21.4375rem;
          margin-bottom: 0;
        }

        .slider-stream__item {
          margin: 0 0.6rem;
        }

        .slider-stream{
          display: flex;
          justify-content: space-between;
          align-items: center;

          &__points{
            display: flex;
            align-items: center;
            margin-top: 1.25rem;
            .point{
              display: block;
              width: 0.312rem;
              height: 0.312rem;
              background: #9CA0A7;
              cursor: pointer;
              border-radius: 50%;
              margin-right: 0.43rem;
              &:last-child{
                margin-right: 0;
              }
            }
          }
        }

        .active-point{
          background-color: #E9242A !important;
          width: 0.437rem !important;
          height: 0.437rem !important;
        }
      }

      &__feed-content {
        margin: 0 -0.6125rem;
        div{
          width: calc(50% - 1.25rem);
          margin: 0 0.6125rem 1.25rem;
        }
      }
      &__content-list {
        margin: 0;

        div {
          width: 100%;
          margin: 1.25rem 0;
        }
      }
    }
  }

  @media screen and (max-width: 420px){
    .news {
      &__feed-content {
        margin: 0;
        div{
          width: 100%;
          margin: 0 0 1.25rem;
        }
      }
      &__content-list {
        margin: 0;

        div {
          width: 100%;
          margin: 1.25rem 0;
        }
      }

      .news-banner {
        width: 100%;
      }

      .slider-stream__item {
        width: 19rem;
        margin: 0;
      }
    }
  }
</style>
