<template>
  <router-link :to="getLink">
  <article :style="getBackgroundImage" :class="{'background__card': getPreviewAttribute, 'news__class': isCardNews}" v-if="!isListView && !content.author_model">
    <img src="/assets/icons/photo.svg" v-if="getMediaTypeCodeAttribute === 'PHOTO_REPORT'" class="media-type__icon">
    <img src="/assets/icons/play_video_icon.svg" v-if="getMediaTypeCodeAttribute === 'VIDEO_REPORT'" class="media-type__icon">
    <div class="media__header" v-if="getMediaTypeCodeAttribute">
      <p class="card__date">
        <img src="/assets/icons/clock_white.svg">
        {{ getFormattedDate(new Date(content[getDateAttribute]))}}
      </p>

      <p v-if="getMediaTypeCodeAttribute === 'PHOTO_REPORT'">
        <img src="/assets/icons/photo.svg">
        {{ content.media_detail }}
      </p>
    </div>

    <p @click.prevent="setRubric" class="card__rubric_news" v-if="!getLiveStreamStartTime && !getMediaTypeCodeAttribute" v-trim="1">
      {{ content.rubric_model ? ($store.getters.GET_LANG ? content.rubric_model.title : content.rubric_model.title_eng) : ($store.getters.GET_LANG ? (content.rubric ? content.rubric : ''):(content.rubric_eng ? content.rubric_eng : ''))}}
    </p>
    <p class="card__live-stream" v-else-if="getLiveStreamStartTime && getLiveStreamTimeLabel" v-trim="1">
      {{ getLiveStreamTimeLabel }} {{ getLiveStreamStartTime }}
    </p>

    <div class="author" v-if="content.full_name">
      <img v-if="content.photo" :src="content.photo">
      <div class="info">
        <p class="name">
          {{ $store.getters.GET_LANG ? content.full_name : content.full_name_eng }}
        </p>

        <p class="date">
          {{ content.publish_date }}
        </p>
      </div>
    </div>

    <p :class="['card__title', {'media__title': getMediaTypeCodeAttribute}]" v-trim="3">
      {{ ($store.getters.GET_LANG ? content.title : content.title_eng) ? ($store.getters.GET_LANG ? content.title : content.title_eng) : ''}}
    </p>

    <p class="card__description" v-if="(content.description || content.description_eng) && !getMediaTypeCodeAttribute && (('description_enable' in content && content.description_enable) || !('description_enable' in content))" v-trim="3">
      {{ $store.getters.GET_LANG ? content.description : content.description_eng }}
    </p>

    <p class="card__date" v-if="getDateAttribute && !getMediaTypeCodeAttribute && !isIndexPage">
      {{ getFormattedDate(new Date(content[getDateAttribute]), 'main_page' in content, 'main_page' in content)}}
    </p>
  </article>

  <article class="for_list" v-else-if="isListView && !content.author_model">
    <div class="col max-w-full photo_anons" v-if="getPreviewAttribute">
      <div class="image__preview" :style="getImage"></div>
      <img src="/assets/icons/photo.svg" v-if="getMediaTypeCodeAttribute === 'PHOTO_REPORT'" class="media-type__icon">
      <img src="/assets/icons/play_video_icon.svg" v-if="getMediaTypeCodeAttribute === 'VIDEO_REPORT'" class="media-type__icon">
    </div>

    <div class="col max-w-full">
      <div class="row">
        <p class="card__rubric" v-if="!getLiveStreamStartTime && !getMediaTypeCodeAttribute">
          {{ content.rubric_model ? ($store.getters.GET_LANG ? content.rubric_model.title : content.rubric_model.title_eng) : (content.rubric ? content.rubric : '')}}
        </p>
        <p class="card__live-stream" v-else-if="getLiveStreamStartTime">
          {{$store.getters.GET_LANG ? 'Начало' : 'Start'}} {{ getLiveStreamStartTime }}
        </p>

        <p class="card__date" v-if="getDateAttribute && content[getDateAttribute]">
          {{ getFormattedDate(new Date(content[getDateAttribute]))}}
        </p>
      </div>

      <div class="row">
        <p class="card__title">
          <!-- {{ content.title ? content.title : ''}} -->
          {{ ($store.getters.GET_LANG ? content.title : content.title_eng) ? ($store.getters.GET_LANG ? content.title : content.title_eng) : ''}}
        </p>
      </div>
    </div>
  </article>

    <article v-else-if="content.author_model" :class="['comment_card', {'comment-list': isListView}]">
      <p class="card__rubric" v-if="content.rubric_model">
        {{ $store.getters.GET_LANG ? content.rubric_model.title : content.rubric_model.title_eng }}
      </p>

      <div class="author">
        <img :src="getAuthorPhoto">

        <div class="info">
          <p class="name">
            {{ ($store.getters.GET_LANG ? content.author_model.first_name : content.author_model.first_name_eng) + ' ' +
              ($store.getters.GET_LANG ? content.author_model.last_name : content.author_model.last_name_eng) }}
          </p>

          <p class="date">
            {{ getFormattedDate(new Date(content.create_date))}}
          </p>
        </div>
      </div>

      <p class="comment_card-title">
      {{ $store.getters.GET_LANG ? content.news_model.title : content.news_model.title_eng }}
      </p>

      <blockquote class="comment_card__block ">
        <!--<div class="ckEditor-html desctop_block" v-html="sliceText(content.text, 60)"></div>-->
        <div class="ckEditor-html desctop_block" v-html="getCommentText"></div>
        <!--<div class="ckEditor-html mobel_block" v-html="sliceText(content.text, 50)"></div>-->
        <div class="ckEditor-html mobel_block" v-html="getCommentText"></div>
      </blockquote>

    </article>
  </router-link>
</template>

<script>
import formatDate from '@/mixins/formatDate'

export default {
  name: 'DynamicNewsCard',

  props: {
    isListView: {
      type: Boolean,
      required: false,
      default: false
    },

    isShortRubric: {
      type: Boolean,
      required: false,
      default: false
    },

    isIndexPage: {
      type: Boolean,
      required: false,
      default: false
    },

    backQuery: {
      type: String,
      required: false,
      default: null
    },

    content: {
      type: Object,
      required: true
    },
    currentTab: {
      type: String
    }
  },

  methods: {
    getTwoCharsTime (value) {
      return value < 10 ? '0' + value : value
    },
    setRubric () {
      if (this.content?.rubric === 3) {
        this.$router.push('/news?tab=Comment')
      } else {
        this.$store.commit('setFilterRubric', this.content?.rubric)
        this.$router.push('/news?tab=News')
      }
      this.$emit('on-change-rubric')
    },
    sliceText (text, value) {
      if (text.length > value) {
        var str = text.slice(0, value)
        // var a = str.split(' ')
        // a.splice(a.length - 1, 1)
        // return a.join(' ') + ' ... '
        return str + ' ... </p>'
      } else return text
    }
  },

  computed: {
    getLink () {
      let route = '/news'

      if ('start_date_time' in this.content) {
        route = '/live_stream'
      }

      if ('archival' in this.content) {
        route = '/announcement'
      }

      if ('media_type' in this.content) {
        route = '/media'
      }

      if ('author_model' in this.content) {
        return '/news/' + (this.content.news_model.slug ? this.content.news_model.slug : this.content.news_id)
      }

      if ('member' in this.content) {
        return `detail_blog/${this.content.id}?memberId=${this.content.member}`
      }
      return route + '/' + this.content.id
    },

    getPreviewAttribute () {
      return this.content.preview_photo ? 'preview_photo'
        : (this.content.preview_media ? 'preview_media' : null)
    },

    isCardNews () {
      return ('short_title' in this.content)
    },

    getDateAttribute () {
      if (this.content.date_time) {
        return 'date_time'
      }

      if (this.content.date) {
        return 'date'
      }

      if (this.content.start_date_time) {
        return 'start_date_time'
      }

      if (this.content.create_date) {
        return 'create_date'
      }

      return null
    },

    getMediaTypeCodeAttribute () {
      return this.content.media_type && this.content.media_type_model
        ? this.content.media_type_model.code
        : null
    },

    getBackgroundImage () {
      // https://img.youtube.com/vi/${this.card.url}/0.jpg
      /*
      if (this.getMediaTypeCodeAttribute === 'VIDEO_REPORT') {
        return {
          background: `linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://img.youtube.com/vi/${this.content.preview_media}/0.jpg) center center / cover`
        }
      }
      */
      return this.getPreviewAttribute ? {
        background: `linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${this.content[this.getPreviewAttribute]}") center center / cover`
      } : ''
    },

    getImage () {
      /*
      if (this.getMediaTypeCodeAttribute === 'VIDEO_REPORT') {
        return {
          background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), url(https://img.youtube.com/vi/${this.content.preview_media}/0.jpg) center center / cover`
        }
      }
      */

      return this.getPreviewAttribute ? {
        background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), url(${this.content[this.getPreviewAttribute]}) center center / cover`
      } : ''
    },

    getLiveStreamStartTime () {
      if (this.content.start_date_time) {
        const date = new Date(this.content.start_date_time)

        return this.getTwoCharsTime(date.getHours()) + ':' + this.getTwoCharsTime(date.getMinutes())
      } else {
        return null
      }
    },

    getLiveStreamTimeLabel () {
      let dateStart = this.content.start_date_time
      let dateEnd = this.content.end_date_time
      let label = ''

      if (dateStart && dateEnd) {
        const now = new Date().getTime()
        dateStart = new Date(dateStart).getTime()
        dateEnd = new Date(dateEnd).getTime()

        if (dateStart < now && dateEnd < now) {
          label = ''
        } else if (dateStart < now && dateEnd > now) {
          label = this.$store.getters.GET_LANG ? 'идет эфир' : 'online'
        } else if (dateStart > now && dateEnd > now) {
          label = this.$store.getters.GET_LANG ? 'скоро начнется' : 'starting soon'
        }
      }

      return label
    },

    getAuthorPhoto () {
      if (this.content.author_model && this.content.author_model.photo) {
        return this.$store.getters.getUrlApi + this.content.author_model.photo.slice(1)
      } else {
        return '/assets/icons/profile_photo.svg'
      }
    },

    getCommentText () {
      const text = this.$store.getters.GET_LANG ? this.content.text : this.content.text_eng
      if (text) {
        const splittedText = text.match(/<p.*?<\/p>/g)
        if (splittedText) {
          return splittedText.slice(0, 1).join('')
        } else {
          return text
        }
      }
      return ''
    }
  },

  mixins: [
    formatDate
  ]
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  .max-w-full {
    max-width: 100%;
  }

  a {
    display: block;
  }

  .for_list > .col > .row:first-of-type {
    flex-wrap: nowrap;
  }

  .author {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: .5rem;

    img {
      width: 3.75rem;
      height: 3.75rem;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      margin-right: 1.25rem;
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .name {
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #1F3245;
      }

      .date {
        font-size: .875rem;
        line-height: 1.375rem;
        color: #5E686C;
        opacity: .8;
      }
    }
  }

  .comment_card {
    min-height: 16.5rem;
    height: auto;

    > .card__rubric, .card__rubric_news{
        margin-bottom: .6rem;
    }

    &-title {
      font-weight: 600;
      font-size: .875rem;
      line-height: 1.375rem;
      min-height: 1.1rem;
      color: rgba(23, 23, 23, 0.8);
    }

    &__block {
      width: 100%;

      margin-top: 0.7rem;
      position: relative;
      padding: .8125rem 3rem;
      box-sizing: border-box;

      color: #5E686C;
      background: #F1F4FA;
      border-radius: .5rem;

      ::v-deep * {
        font-size: .875rem;
        line-height: 1.375rem;
      }
    }
    &__block:before,
    &__block:after {
      position: absolute;
      background: url('../../assets/icons/quotes.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 0.7rem;
      height: 0.7rem;
    }
    &__block:before {
      content: '';
      left: 1.3rem;
      top: 1rem;
    }
    &__block:after {
      content: '';
      right: 1.3rem;
      bottom: 1rem;
    }

  }

  .comment-list {
    width: 100%;
  }

  .media-type__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    object-fit: contain;
    object-position: center;
  }

  article {
    background: #FFFFFF;
    border: 1px solid #EBF3FF;
    box-sizing: border-box;
    box-shadow: 0 .9375rem 1.5625rem rgba(189, 205, 221, 0.35);
    border-radius: .5rem;
    padding: 1.25rem 1.875rem;

    width: 26.5625rem;
    height: 15.625rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    .media-type__icon {
      width: 4.0625rem;
      height: 4.25rem;
    }

    .media__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      > p {
        display: flex;
        align-items: center;
        color: white;
        font-size: .8125rem;
        line-height: 1.25rem;

        > img {
          width: .8125rem;
          height: .8125rem;
          object-fit: contain;
          object-position: center;
          margin-right: .5625rem;
        }
      }
    }

    .card__rubric {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .card__rubric, .card__live-stream, .card__rubric_news {
      padding: .125rem .625rem;
      background: #EEF4FA;
      border-radius: .1875rem;

      text-transform: uppercase;
      color: #306292;
      font-weight: 600;
      font-size: .8125rem;
      line-height: 1rem;
      margin-bottom: 1.25rem;
    }

    .card__live-stream {
      background-color: #8ABD1F;
      color: white;
    }

    .card__title {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.5rem;
      margin-bottom: .375rem;
      color: #1F3245;
    }

    .media__title {
      margin-top: auto;
      margin-bottom: 0;
    }

    .card__description {
      font-size: .875rem;
      line-height: 1.375rem;
      color: rgba(23, 23, 23, 0.8);
    }

    .card__date {
      margin-top: auto;
      font-size: .75rem;
      line-height: 1.125rem;
      color: rgba(23, 23, 23, 0.8) !important;
    }

    &.background__card {
      .card__rubric {
        background-color: white;
      }

      .card__title, .card__description, .card__date {
        color: white !important;
      }
    }

    &.for_list {
      background: white !important;
      width: 100%;
      height: auto;
      flex-direction: row;

      .media-type__icon {
        width: 1.8124rem;
        height: 1.3125rem;
      }

      .col {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-right: 1.375rem;

        .image__preview {
          width: 6.25rem;
          height: 4.375rem;
          object-fit: cover;
          object-position: center;
          border-radius: .375rem;
        }

        .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;

          .card__live-stream, .card__rubric {
            margin-bottom: 0;
            margin-right: 1.25rem;
          }

          .card__rubric {
            background-color: #EEF4FA;
            margin-bottom: .75rem !important;
          }

          .card__title {
            color: #1F3245 !important;
          }

          .card__date {
            margin-top: 0;
            font-size: .875rem;
            line-height: 1.375rem;
            margin-bottom: .75rem !important;
          }

          .card__description, > .card__date {
            color: rgba(23, 23, 23, 0.8) !important;
          }
        }
      }
    }
  }
  .desctop_block{
    /*max-height: 2.75rem;*/
    overflow: hidden;
  }
  .mobel_block{
    display: none;
  }
  @media screen and (max-width: 420px) {

    .news__class {
      .card__description {
        display: none !important;
      }
    }

    .card__date {
      min-width: 8.5rem;
    }

    a {
      width: 100%;

      .image__preview {
        display: none;
      }

      .photo_anons{//:first-of-type {
        display: none;
      }

      article {
        width: 100%;

        .card__title {
          display: block !important;
        }
      }

      .row {
        flex-wrap: wrap;
        align-items: flex-start !important;

        .card__rubric {
          margin-bottom: .75rem !important;
          max-width: 9rem;
        }
        .card__title{
            font-size: 1rem;
        }
      }
    }
    .desctop_block{
      display: none;
    }
    .mobel_block{
      display: block;
      max-height: 4rem;
      overflow: hidden;
    }
    .comment_card{
      min-height: 17.5rem;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    article {
      .card__title, .card__description, .comment_card-title {
        width: 100%;
      }

      .col {
        width: 100%;
      }

      .photo_anons {
        width: 20% !important;
      }
    }
  }
</style>
