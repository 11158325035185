<template>
  <footer class="footer">
    <div class="container">

      <div class="footer-content">
        <div class="footer__hot-line">
          <router-link to="/">
            <img src="@/assets/icons/xx/bl_red_logo_footer.png" class="hot-line__logo">
          </router-link>
          <div class="hot-line">
            <p class="hot-line__title">{{ $store.getters.GET_LANG ? 'Наша горячая линия:' : 'Our hotline:' }}</p>
            <a class="hot-line__phone" :href="`tel:${getFeedbackHotline}`">{{ getFeedbackHotline }}</a>
            <p class="hot-line__polls" v-if="callIsFree">{{ $store.getters.GET_LANG ? 'Звонок бесплатный по всей России' : 'The call is free throughout Russia' }}</p>
            <div class="soc-icons__container">
              <!--<a href="https://www.facebook.com/CivicChamber/?sfrm" target="_blank">
                <img src="@/assets/icons/soc_facebook.svg" alt="">
              </a>-->
              <a href="https://vk.com/oprf_official" target="_blank">
                <img src="@/assets/icons/soc_vk.svg" alt="">
              </a>
              <!--<a href="https://www.instagram.com/oprf_official/" target="_blank">
                <img src="@/assets/icons/insta_small.svg" alt="">
              </a>-->
              <a href="https://t.me/oprf_official" target="_blank">
                <img src="@/assets/icons/soc_telegram.svg" alt="">
              </a>
              <div style="width: 1.8rem; height: 1.8rem">
                <a href="https://rutube.ru/channel/24218630" target="_blank">
                  <img src="@/assets/icons/soc_rutube.png" style="width: 1.2rem; height: 1.2rem; margin-top: -5px; margin-left: 5px;" alt="">
                </a>
              </div>

              <!-- <a href="" target="_blank">
                <img src="@/assets/icons/soc_rss.svg" alt="">
              </a> -->
            </div>
          </div>
        </div>

        <div class="footer__column-links">
          <h6 class="column-links__title" @click="isActiveNews = !isActiveNews">{{ $store.getters.GET_LANG ? 'Новости' : 'News' }}</h6>
          <div class="column-links__wrapper" :class="{'active': !!isActiveNews}">
            <router-link class="column-links__link" to="/news?tab=News">{{ $store.getters.GET_LANG ? 'Новости' : 'News' }}</router-link>
            <router-link v-if="$store.getters.GET_LANG" class="column-links__link" to="/news?tab=Announcements">{{ $store.getters.GET_LANG ? 'Анонсы' : 'Announcements' }}</router-link>
            <router-link v-if="$store.getters.GET_LANG" class="column-links__link" to="/news?tab=Comment">{{ $store.getters.GET_LANG ? 'Комментарии' : 'Comments' }}</router-link>
            <router-link class="column-links__link" to="/news?tab=Media">{{ $store.getters.GET_LANG ? 'Медиаматериалы' : 'Media materials' }}</router-link>
          </div>
        </div>

        <div class="footer__column-links">
          <h6 class="column-links__title" @click="isActiveChamber = !isActiveChamber">{{ $store.getters.GET_LANG ? 'О Палате' : 'About the Chamber' }}</h6>
          <div class="column-links__wrapper" :class="{'active': !!isActiveChamber}">
            <router-link class="column-links__link" href="/members_chamber">{{ $store.getters.GET_LANG ? 'Члены ОП РФ' : 'Members of the CC RF' }}</router-link>
            <router-link class="column-links__link" href="/structure_chamber">{{ $store.getters.GET_LANG ? 'Структура ОП РФ' : 'The structure of the CC RF' }}</router-link>
            <router-link v-if="$store.getters.GET_LANG" class="column-links__link" to="/normative_base">{{ $store.getters.GET_LANG ? 'Нормативная база' : 'Normative base' }}</router-link>
            <router-link v-if="$store.getters.GET_LANG" class="column-links__link" to="/staff_chamber">{{ $store.getters.GET_LANG ? 'Аппарат' : 'Apparatus' }}</router-link>
            <router-link v-if="$store.getters.GET_LANG" class="column-links__link" to="/partners_chamber">{{ $store.getters.GET_LANG ? 'Партнеры' : 'Partners' }}</router-link>
            <router-link v-if="$store.getters.GET_LANG" class="column-links__link" to="/anti_corruption_measures">{{ $store.getters.GET_LANG ? 'Противодействие коррупции' : 'Anti-corruption' }}</router-link>
          </div>
        </div>

        <div class="footer__column-links" v-if="$store.getters.GET_LANG">
          <h6 class="column-links__title" @click="isActiveActual = !isActiveActual">{{ $store.getters.GET_LANG ? 'Деятельность' : 'Activities' }}</h6>
          <div class="column-links__wrapper" :class="{'active': !!isActiveActual}">
            <router-link class="column-links__link" to="/actual">{{ $store.getters.GET_LANG ? 'Актуальное' : 'Activities' }}</router-link>
            <router-link class="column-links__link" to="/activities_projects">{{ $store.getters.GET_LANG ? 'Проекты' : 'Projects' }}</router-link>
            <router-link class="column-links__link" to="/public-examination">{{ $store.getters.GET_LANG ? 'Общественная экспертиза' : 'Public expertise' }}</router-link>
            <router-link class="column-links__link" to="/activity_documents">{{ $store.getters.GET_LANG ? 'Документы и издания' : 'Documents' }}</router-link>
            <router-link class="column-links__link" to="/public_chamber_news">{{ $store.getters.GET_LANG ? 'Общественные палаты регионов' : 'Public chambers of the regions' }}</router-link>
            <router-link class="column-links__link" to="/public_councils">{{ $store.getters.GET_LANG ? 'Общественные советы при ФОИВ' : 'Public councils at federal executive authorities' }}</router-link>
            <router-link class="column-links__link" to="/public_monitoring_commissions">{{ $store.getters.GET_LANG ? 'Общественные наблюдательные комиссии' : 'Public oversight commissions' }}</router-link>
          </div>
        </div>

        <div class="footer__column-links" v-if="$store.getters.GET_LANG">
          <h6 class="column-links__title" @click="isActiveContacts = !isActiveContacts">{{ $store.getters.GET_LANG ? 'СВЯЗАТЬСЯ' : 'get in touch with' }}</h6>
          <div class="column-links__wrapper" :class="{'active': !!isActiveContacts}">
            <router-link class="column-links__link" to="/contacts">{{ $store.getters.GET_LANG ? 'Контакты' : 'Contacts' }}</router-link>
            <!-- <router-link class="column-links__link" to="/contacts">{{ $store.getters.GET_LANG ? 'Обратная связь' : 'Feedback' }}</router-link> -->
<!--            <router-link tag="li" class="column-links__link" to="/">Горячие линии</router-link>-->
<!--            <router-link tag="li" class="column-links__link" to="/appeal">Обращения граждан</router-link>-->
            <li class="column-links__link"><a class="column-links__link" href="https://eis.oprf.ru/treatments/send">{{ $store.getters.GET_LANG ? 'Обращения граждан' : `Citizens' appeals` }}</a></li>
            <router-link class="column-links__link" to="/for_media">{{ $store.getters.GET_LANG ? 'Для СМИ' : 'For the media' }}</router-link>
          </div>
        </div>

      </div>

    </div>

    <div class="footer-license">
      <div class="container">
        <p>{{ $store.getters.GET_LANG ? 'Все материалы сайта доступны по лицензии:' : 'All site materials are available under license:' }} Creative Commons Attribution 4.0 International</p>
        <p class="copyright">© 2021 {{ $store.getters.GET_LANG ? 'Общественная палата Российской Федерации' : 'Civic Chamber of the Russian Federation' }}</p>
      </div>
    </div>

    <div class="footer-info">
      <div class="container">
        <p> {{ $store.getters.GET_LANG ? `Электронное периодическое издание «Официальный сайт Общественной палаты Российской Федерации» (зарегистрировано Федеральной службой по надзору в сфере массовых коммуникаций, связи
          и охраны культурного наследия – свидетельство Эл № ФС77 – 30375 от 16 ноября 2007 г., перерегистрировано 06.07.2011 г., Эл № ФС 77 – 45656, перерегистрировано 28.12.2012г., Эл № ФС77-52413).` : `Electronic periodical "Official website of the Civic Chamber of the Russian Federation" (registered by the Federal Service for Supervision of Mass Communications, Communications
          and protection of cultural heritage - certificate El No. FS77 - 30375 dated November 16, 2007, re-registered on 06.07.2011, El No. FS 77 - 45656, re-registered on December 28, 2012, El No. FS77-52413).` }}</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'MainFooter',
  data () {
    return {
      isActiveNews: false,
      isActiveChamber: false,
      isActiveActual: false,
      isActiveContacts: false
    }
  },
  computed: {
    getFeedbackHotline () {
      return this.$store.getters.getFeedbackHotline?.phone
    },
    callIsFree () {
      const phoneStartWith = this.getFeedbackHotline?.replace(/\)|\(| |-|,|\+|/g, '')?.substr(0, 4)
      return phoneStartWith === '8800' || phoneStartWith === '7800'
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      await this.$store.dispatch('getContacts', {
        headers: {
          Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      this.$store.dispatch('setFeedbackHotline')
    }
  }
}
</script>

<style scoped lang="scss">

  .soc-icons__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.5rem;

    img {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

.column-links__wrapper {
  display: flex;
  flex-direction: column;
}
.footer {
  margin-top: 8rem;
  .container {
    display: flex;
  }

  &-content {
    width: 100%;
    display: flex;
    margin-bottom: 6.25rem;
  }

  &__hot-line {
    margin-right: 4rem;
    .hot-line {
      &__logo {
        width: 12.25rem;
        margin-bottom: 2rem;
      }

      &__title {
        font-weight: 600;
        font-size: 0.81rem;
        color: #171717;
      }

      &__phone {
        font-weight: bold;
        font-size: 1.5rem;
        color: #246CB7;
      }

      &__polls {
        font-size: 0.81rem;
        color: #9D9D9D;
      }

    }
  }

  &__column-links {
    .column-links {
      &__title {
        font-weight: 600;
        font-size: 1rem;
        line-height: 156%;
        text-transform: uppercase;
        color: #003E78;
        mix-blend-mode: normal;
        margin-bottom: 1.56rem;
      }

      &__link{
        font-size: 0.87rem;
        line-height: 129%;
        margin-bottom: 1.25rem;
        color: #003E78;
        mix-blend-mode: normal;
        cursor: pointer;
      }
    }
  }

  &__column-links {
    margin-right: 7rem;
    &:last-child {
      margin-right: 0;
    }
    max-width: 15rem;
    display: flex;
    flex-direction: column;
  }

  &-license{
    border: 1px solid #E8E9EA;
    border-right: none;
    border-left: none;
    padding: 1.75rem 0;
    .container{
      display: flex;
      justify-content: space-between;

      p{
        font-size: 0.81rem;
        line-height: 123%;
        color: #404040;
        opacity: 0.8;
      }
    }
  }

  &-info{
    background-color: #E8E9EA;
    padding: 1.75rem 0;
    .container{
      p{
        font-size: 0.81rem;
        line-height: 123%;
        color: #404040;
        opacity: 0.8;
      }
    }
  }
}

@media screen and (max-width: 768px){
  .footer {
    margin-top: 3.125rem;

    &__hot-line {
      display: flex;
      justify-content: space-between;

      div {
        width: 47%;
      }
    }

    &-content {
      flex-direction: column;
      margin-bottom: 0;
    }

    &__column-links {
      flex: 0 0 auto;
      width: 100%;
      max-width: none;
      margin-right: 0;
      border-bottom: 1px solid #D5D6D9;
      cursor: pointer;

      .column-links {
        &__wrapper {
          display: none;
        }

        &__wrapper.active {
          display: flex;
        }

        &__title {
          margin: 0;
          padding: 0.75rem 0;
        }

        &__link {

        }
      }
    }

  }
}

@media screen and (max-width: 420px){
  .footer {
    margin-top: 3.125rem;
    &__column-links{
      max-width: 100%;
    }

    &__hot-line {
      flex-wrap: wrap;

      div {
        width: 100%;
      }
    }

    .copyright {
      display: none;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .hot-line {
    margin-top: -13rem;
  }
}

</style>
