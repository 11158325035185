<template>
  <form @submit.prevent="createAccreditation" ref="Аccreditation">
    <div class="contain">
      <div class="contain__element">
        <p> {{ $store.getters.GET_LANG ? 'Фамилия' : 'Last name' }}<span>*</span></p>
        <input type="text" name="firstName" id="firstName" v-model="personalData.firstName"
               :class="{'active-classe': (activeClass && (personalData.firstName ===''))}">
      </div>
      <div class="contain__element">
        <p> {{ $store.getters.GET_LANG ? 'Имя' : 'First name' }}<span>*</span></p>
        <input type="text" name="lastName" id="lastName" v-model="personalData.lastName"
               :class="{'active-classe': (activeClass && (personalData.lastName ===''))}">
      </div>
      <div class="contain__element">
        <p> {{ $store.getters.GET_LANG ? 'Отчество' : 'Patronymic' }}<span>*</span></p>
        <input type="text" name="patronymic" id="fullName" v-model="personalData.patronymic"
               :class="{'active-classe': (activeClass && (personalData.patronymic ===''))}">
      </div>
      <div class="contain__element">
        <p>Email<span>*</span></p>
        <input type="email" name="email" id="email" v-model="personalData.email"
               :class="{'active-classe':activeClass && personalData.email ===''}">
      </div>
      <div class="contain__element">
        <p>{{ $store.getters.GET_LANG ? 'Телефон' : 'Telephone' }}<span>*</span></p>
        <input type="tel" name="phone" id="phone" placeholder="+7 ___-___-__-__"
               pattern="+7-[0-9]{3}-[0-9]{3}-[0-9]{2}-[0-9]{2}"
               v-model="personalData.phone" :class="{'active-classe':activeClass && personalData.phone ===''}">
      </div>

      <div class="contain__element">
        <p> {{ $store.getters.GET_LANG ? 'Название мероприятия' : 'The name of the event' }}<span>*</span></p>
        <input type="text" name="event" id="event" v-model="personalData.event"
               :class="{'active-classe':activeClass && personalData.event ===''}">
      </div>
      <div class="contain__element">
        <p> {{ $store.getters.GET_LANG ? 'Дата/время мероприятия' : 'Date/time of the event' }}<span>*</span></p>
        <!-- <input type="text" name="date" id="date" v-model="personalData.date" :class="{'active-classe':activeClass && personalData.date ===''}"> -->
        <input required type="datetime-local" v-model="personalData.date"
               :class="{'active-classe':activeClass && personalData.date ===''}" name="date" class="input blue">
      </div>
      <div class="contain__element">
        <p> {{ $store.getters.GET_LANG ? 'СМИ' : 'Mass media' }}<span>*</span></p>
        <input type="text" name="massMedia" id="massMedia" v-model="personalData.massMedia"
               :class="{'active-classe':activeClass && personalData.massMedia ===''}">
      </div>
      <!-- <div class="contain__element">
        <p>Тематика<span>*</span></p>
        <input type="text" name="topic" id="thematics" v-model="personalData.topic"
               :class="{'active-classe':activeClass && personalData.topic ===''}">
      </div> -->

    </div>

    <div class="block_post">
      <div class="checkbox_block">
        <input class="custom-checkbox" type="checkbox" id="assent" v-model="checkboxValue"
               :class="{'box__active': checkboxValue}">
        <label for="assent"></label>
        <p>
          {{ $store.getters.GET_LANG ? 'Согласие обработки персональных данных “в соответствии с требованиями Федерального закона от 27.07.2006 года № 152-ФЗ «О персональных данных»”'
            : 'Consent to the processing of personal data "in accordance with the requirements of Federal law № 152-FZ of 27.07.2006 «On personal data»”' }}</p>
      </div>
      <button class="btn-blue" :disabled="loading"> {{ $store.getters.GET_LANG ? 'Отправить' : 'Submit' }} </button>
    </div>
  </form>
</template>
<script>

export default {
  name: 'MediaAccreditationForm',
  data () {
    return {
      activeClass: false,
      loading: false,
      activeColor: false,
      checkboxValue: '',
      personalData: {
        phone: '',
        fullName: '',
        email: '',
        position: '',
        eventName: '',
        date: '',
        massMedia: '',
        thematics: '',
        comment: ''
      },
      getDate: [
        {
          title: 'Все',
          name: ''
        }
      ]
    }
  },
  computed: {
    valueInput () {
      return (this.personalData.phone || this.personalData.fullName || this.personalData.position || this.personalData.email ||
        this.personalData.eventName || this.personalData.date || this.personalData.massMedia || this.personalData.thematics ||
        this.personalData.comment)
    }
  },
  watch: {
    valueInput () {
      this.$emit('personalData', this.personalData)
    }
  },
  methods: {
    setDate (value) {
      this.personalData.date = value.title
    },
    createAccreditation () {
      if (this.loading) return
      if (this.checkboxValue) {
        const formData = new FormData(this.$refs.Аccreditation)
        formData.append('topic', null)
        this.loading = true
        fetch(`${this.$store.getters.getUrlApi}api/admin/accreditation`, {
          method: 'POST',
          body: formData
        })
          .then(res => {
            if (res.status === 200) {
              this.$noty.success('Форма успешно отправлена')
              this.$refs.Аccreditation.reset()
            } else {
              this.$noty.error('При отправлении данных произошла ошибка')
            }
            return res.json()
          })
          .catch(error => console.log('error', error)).finally(() => {
            this.loading = false
          })
      } else {
        this.$noty.error('Вы должны согласиться с обработкой персональных данных')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.btn-blue:disabled {
  opacity: 0.3;
  cursor: wait;
}
.contain {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: .25rem;
  margin-right: -2.19rem;

  &__element {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 19.375rem;
    margin: 0 2.19rem 1.88rem 0;

    p {
      font-size: .875rem;
      line-height: 1.375rem;
      color: #1F3245;
      margin-bottom: .56rem;
    }

    span {
      color: #EB494E;
    }

    input {
      width: 100%;
      height: 2.1875rem;
      padding: 0 .75rem;
      background: #EEF4FA;
      border-radius: .19rem;

      font-weight: 600;
      font-size: .875rem;
      line-height: 1.38rem;
      color: #1F3245;
    }

    input::placeholder {
      font-weight: normal !important;
      color: #5A79A8 !important;
      opacity: 0.55;
    }

    input:focus {
      background: #DDEDFE;
    }
  }

  &__element:nth-child(1), &__element:nth-child(2), &__element:nth-child(3) {
    p {
      font-weight: 600;
    }
  }

  &__element:last-child {
    width: calc(50% - 2.19rem);
  }
}

.error__show {
  display: flex;
  margin-bottom: 1.74rem;
  font-weight: 600;
  font-size: .813rem;
  line-height: 1.25rem;
  color: #EB494E;

  svg {
    width: 2rem;
    height: 2rem;
  }

  p {
    padding: .125rem 0 0 .38rem;
  }
}

.active-classe {
  border: .06rem solid #EB494E;
  box-sizing: border-box;
}

.block_post {
  display: flex;
  justify-content: space-between;
  padding-top: 2.5rem;
  border-top: .063rem solid #D5D6D9;
}

.checkbox_block {
  display: flex;

  p {
    width: 53.44rem;
    font-size: .75rem;
    line-height: 1.125rem;
    color: #1F3245;
    margin-left: .69rem;
  }

  label {
    margin-bottom: 0;
  }
}

.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: auto !important;
}

.custom-checkbox + label::before {
  content: '';
  display: block;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: .19rem;
  background-color: #DDEDFE;
  cursor: pointer;
}

.box__active + label::before {
  background: #DDEDFE url("../../assets/icons/check.svg") center center no-repeat;
  background-size: 80%;
}

.active_color {
  opacity: 1;
}
@media screen and (max-width: 768px){
  .block_post{
    flex-direction: column;
    .checkbox_block {
      display: flex;
      p {
        width: 80%;
      }
    }
    .btn-blue {
      width: max-content;
      margin: 1rem 0 0 1.815rem;
    }
  }
}
@media screen and (max-width: 420px) {
  .contain__element,
  .contain__element:last-child{
    width: 100%;
    margin-right: 0;
  }
  input[type="date"]{
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}
</style>
