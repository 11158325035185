<template>
  <header :class="{ 'index-page-header': isIndexPage }">
    <div class="container header" :class="{ 'index-page': isIndexPage }">
      <router-link to="/">
        <img src="@/assets/icons/xx/wh_red_logo_header.png" alt="" class="header-logo" v-if="isIndexPage">
        <img src="@/assets/icons/xx/bl_red_logo_footer.png" alt="" class="header-logo mt-logo" v-else>
      </router-link>
      <nav class="header-nav">
        <div class="dropdown">
            <button class="dropbtn">
              <router-link v-if="getAboutTheWard && getAboutTheWard.length" style="margin-left: 0" to="/news?tab=All">
                {{ $store.getters.GET_LANG ? 'Новости' : 'News' }}
              </router-link></button>
            <div class="dropdown-content">
              <router-link to="/news?tab=All">{{ $store.getters.GET_LANG ? 'Новости' : 'News' }}</router-link>
              <router-link to="/news?tab=Comment">{{ $store.getters.GET_LANG ? 'Комментарии' : 'Comments' }}</router-link>
              <router-link v-if="$store.getters.GET_LANG" to="/news?tab=Announcements">{{ $store.getters.GET_LANG ? 'Анонсы' : 'Announcements' }}</router-link>
              <router-link v-if="!$store.getters.GET_LANG" to="/news?tab=Blogs">Blogs</router-link>
              <router-link to="/news?tab=Media">{{ $store.getters.GET_LANG ? 'Медиаматериалы' : 'Media materials' }}</router-link>
            </div>
        </div>

        <div class="dropdown">
            <button class="dropbtn">
              <router-link v-if="getAboutTheWard && getAboutTheWard.length" style="margin-left: 0" :to="`/about_the_chamber/${getAboutTheWard[getAboutTheWard.length-1].id}`">
                {{ $store.getters.GET_LANG ? 'О Палате' : 'About the Chamber' }}
              </router-link>
            </button>
            <div class="dropdown-content">
              <!-- <router-link to="/members_chamber">{{ $store.getters.GET_LANG ? 'Члены ОП РФ' : 'Members of the CC RF' }}</router-link> -->
              <router-link to="/members_chamber">{{ $store.getters.GET_LANG ? 'Члены ОП РФ' : 'Members of the CC RF' }}</router-link>
              <router-link to="/structure_chamber">{{ $store.getters.GET_LANG ? 'Структура ОП РФ' : 'The structure of the CC RF' }}</router-link>
              <router-link v-if="$store.getters.GET_LANG" to="/normative_base">{{ $store.getters.GET_LANG ? 'Нормативная база' : 'Normative base' }}</router-link>
              <router-link v-if="$store.getters.GET_LANG" to="/staff_chamber">{{ $store.getters.GET_LANG ? 'Аппарат' : 'Apparatus' }}</router-link>
              <router-link v-if="$store.getters.GET_LANG" to="/partners_chamber">{{ $store.getters.GET_LANG ? 'Партнеры' : 'Partners' }}</router-link>
              <!-- <router-link to="/anti_corruption_measures">{{ $store.getters.GET_LANG ? 'Противодействие коррупции' : 'Anti-corruption' }}</router-link> -->
            </div>
        </div>

        <!-- <div class="dropdown" v-if="!$store.getters.GET_LANG && getAboutTheWard.length">
          <router-link class="dropbtn" :to="`/about_the_ward/${getAboutTheWard[getAboutTheWard.length-1].id}`">
            About the Chamber
            {{ $store.getters.GET_LANG ? 'О палате ' : 'About the Chamber'}}
          </router-link>
        </div> -->

        <div class="dropdown" v-if="$store.getters.GET_LANG">
            <button class="dropbtn">
              <router-link v-if="getAboutTheWard && getAboutTheWard.length" style="margin-left: 0" to="/actual">
                {{ $store.getters.GET_LANG ? 'Деятельность' : 'Activities' }}
              </router-link>
            </button>
            <div class="dropdown-content">
              <router-link to="/actual">{{ $store.getters.GET_LANG ? 'Актуальное' : 'Activities' }}</router-link>
              <router-link to="/activities_projects">{{ $store.getters.GET_LANG ? 'Проекты' : 'Projects' }}</router-link>
              <router-link to="/public-examination">{{ $store.getters.GET_LANG ? 'Общественная экспертиза' : 'Public expertise' }}</router-link>
              <router-link to="/activity_documents">{{ $store.getters.GET_LANG ? 'Документы и издания' : 'Documents' }}</router-link>
              <router-link to="/public_chamber_news">{{ $store.getters.GET_LANG ? 'Общественные палаты регионов' : 'Public chambers of the regions' }}</router-link>
              <router-link to="/public_councils">{{ $store.getters.GET_LANG ? 'Общественные советы при ФОИВ' : 'Public councils at federal executive authorities' }}</router-link>
              <router-link to="/public_monitoring_commissions">{{ $store.getters.GET_LANG ? 'Общественные наблюдательные комиссии' : 'Public oversight commissions' }}</router-link>
            </div>
          </div>
        <!-- <router-link to="/">Деятельность</router-link> -->

        <div class="dropdown" v-if="$store.getters.GET_LANG">
            <button class="dropbtn">
              <router-link v-if="getAboutTheWard && getAboutTheWard.length" style="margin-left: 0" to="/contacts">
                {{ $store.getters.GET_LANG ? 'СВЯЗАТЬСЯ' : 'get in touch with' }}
              </router-link>
              </button>
            <div class="dropdown-content">
              <router-link v-if="$store.getters.GET_LANG" to="/contacts">{{ $store.getters.GET_LANG ? 'Контакты' : 'Contacts' }}</router-link>
<!--              <router-link to="/appeal">Обращения граждан</router-link>-->
              <a href="https://eis.oprf.ru/treatments/send">{{ $store.getters.GET_LANG ? 'Обращения граждан' : `Citizens' appeals` }}</a>
              <router-link to="/for_media">{{ $store.getters.GET_LANG ? 'Для СМИ' : 'For the media' }}</router-link>
            </div>
          </div>

        <div class="dropdown" v-for="section in getSections" :key="`section${section.id}`">
          <button class="dropbtn">
            <router-link style="margin-left: 0" :to="getMainPage(section).mainPage ? `/page/${getMainPage(section).mainPage.id}` : '/'">
              {{ section.title }}
            </router-link>
          </button>
          <div class="dropdown-content" v-if="getMainPage(section).list.length">
            <router-link v-for="page in getMainPage(section).list"
                         :key="`${section.id}sectionPage${page.id}`"
                         :to="`/page/${page.id}`">
              {{ page.title }}
            </router-link>
          </div>
        </div>
      </nav>

      <div class="header-icons">

        <svg  @click="isIndexPage ? $router.push({ name: 'Search'}) : isActiveSearch = !isActiveSearch" class="svg-simple" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.7661 16.6116L13.0934 11.9614C14.1494 10.6635 14.7272 9.04188 14.73 7.36839C14.73 5.91106 14.2981 4.48646 13.4888 3.27474C12.6795 2.06301 11.5293 1.11858 10.1835 0.560889C8.83772 0.00319254 7.35685 -0.142726 5.92818 0.141585C4.49951 0.425896 3.18718 1.12767 2.15717 2.15815C1.12715 3.18864 0.425701 4.50157 0.14152 5.93089C-0.142661 7.36022 0.00319111 8.84176 0.560632 10.1882C1.11807 11.5346 2.06207 12.6853 3.27324 13.495C4.48441 14.3046 5.90836 14.7368 7.36503 14.7368C9.03775 14.734 10.6586 14.1559 11.9559 13.0994L16.604 17.7578C16.6801 17.8346 16.7706 17.8955 16.8703 17.937C16.9701 17.9786 17.077 18 17.1851 18C17.2931 18 17.4 17.9786 17.4998 17.937C17.5995 17.8955 17.69 17.8346 17.7661 17.7578C17.916 17.6048 18 17.399 18 17.1847C18 16.9704 17.916 16.7647 17.7661 16.6116ZM7.36503 13.0994C6.23206 13.0994 5.12455 12.7633 4.18252 12.1335C3.2405 11.5038 2.50628 10.6087 2.07272 9.56154C1.63915 8.51435 1.52571 7.36204 1.74674 6.25034C1.96777 5.13864 2.51335 4.11748 3.31447 3.31599C4.11559 2.5145 5.13629 1.96867 6.24748 1.74754C7.35867 1.52641 8.51045 1.6399 9.55717 2.07367C10.6039 2.50743 11.4985 3.24198 12.128 4.18444C12.7574 5.12689 13.0934 6.23492 13.0934 7.36839C13.0934 8.88834 12.4899 10.346 11.4156 11.4208C10.3413 12.4956 8.88428 13.0994 7.36503 13.0994Z"
          />
        </svg>

          <p @click="changeLang( !getLang )" v-if="getEngFlag">
            {{ getLang ? 'En' : 'Ru' }}
          </p>

        <svg  id="specialButton" class="svg-stroke" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M25.5124 9.99139L19.6905 1.64041L19.6352 1.5761C19.6069 1.54895 18.9285 0.909337 17.9609 1.01108C17.1939 1.09053 16.4937 1.59525 15.8794 2.51123L16.6234 2.97765C17.0822 2.29317 17.5609 1.91877 18.0466 1.86447C18.5161 1.81017 18.8883 2.08396 18.9858 2.16313L23.1901 8.19402C22.4568 7.87278 21.6455 7.68958 20.7896 7.68958C18.631 7.68958 16.7463 8.8302 15.7338 10.5181C15.3237 10.1849 14.6364 9.80277 13.6343 9.80277C12.6339 9.80277 11.9475 10.1837 11.5371 10.5167C10.5243 8.82963 8.6402 7.68958 6.4822 7.68958C5.63513 7.68958 4.83208 7.86849 4.10467 8.18316L8.30101 2.16341C8.40176 2.08139 8.77404 1.81217 9.24056 1.86447C9.72629 1.91849 10.2052 2.29317 10.6641 2.97765L11.4077 2.51094C10.7938 1.59496 10.0932 1.09025 9.32624 1.0108C8.35774 0.909909 7.68026 1.54866 7.6519 1.57582L7.62117 1.60554L1.77472 9.99139L1.78151 9.99597C1.06504 10.9348 0.635742 12.0906 0.635742 13.3447C0.635742 16.463 3.2582 18.9997 6.4822 18.9997C9.7062 18.9997 12.3287 16.463 12.3287 13.3447C12.3287 12.6322 12.1859 11.9525 11.9363 11.3241C12.1635 11.0909 12.708 10.6599 13.6346 10.6599C14.5596 10.6599 15.1062 11.0917 15.3352 11.3266C15.0861 11.9545 14.9437 12.633 14.9437 13.3449C14.9437 16.4633 17.5662 19 20.7902 19C24.0142 19 26.6357 16.463 26.6357 13.3447C26.6357 12.0937 26.2085 10.9402 25.495 10.0028L25.5124 9.99139ZM6.4822 18.1423C3.74717 18.1423 1.52211 15.99 1.52211 13.3447C1.52211 10.699 3.74717 8.54669 6.4822 8.54669C9.21722 8.54669 11.4423 10.699 11.4423 13.3447C11.442 15.99 9.21692 18.1423 6.4822 18.1423ZM25.7494 13.3447C25.7494 15.99 23.5243 18.1423 20.7893 18.1423C18.0543 18.1423 15.8292 15.99 15.8292 13.3447C15.8292 10.699 18.0543 8.54669 20.7893 8.54669C23.5243 8.54669 25.7494 10.699 25.7494 13.3447Z"
            stroke-width="0.6"/>
        </svg>

        <svg @click="isActiveAuth = true" v-if="!personalAccount" class="svg-stroke" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.7075 19H10.9217C10.4102 19 9.91971 18.7968 9.55803 18.4351C9.19635 18.0735 8.99316 17.5829 8.99316 17.0714V15.7857C8.99316 15.6152 9.06089 15.4517 9.18145 15.3311C9.30201 15.2106 9.46552 15.1429 9.63602 15.1429C9.80652 15.1429 9.97003 15.2106 10.0906 15.3311C10.2111 15.4517 10.2789 15.6152 10.2789 15.7857V17.0714C10.2789 17.2419 10.3466 17.4054 10.4672 17.526C10.5877 17.6466 10.7512 17.7143 10.9217 17.7143H16.7075C16.8779 17.7143 17.0415 17.6466 17.162 17.526C17.2826 17.4054 17.3503 17.2419 17.3503 17.0714V2.92857C17.3503 2.75808 17.2826 2.59456 17.162 2.474C17.0415 2.35344 16.8779 2.28571 16.7075 2.28571H10.9217C10.7512 2.28571 10.5877 2.35344 10.4672 2.474C10.3466 2.59456 10.2789 2.75808 10.2789 2.92857V4.21429C10.2789 4.38478 10.2111 4.5483 10.0906 4.66885C9.97003 4.78941 9.80652 4.85714 9.63602 4.85714C9.46552 4.85714 9.30201 4.78941 9.18145 4.66885C9.06089 4.5483 8.99316 4.38478 8.99316 4.21429V2.92857C8.99316 2.41708 9.19635 1.92654 9.55803 1.56487C9.91971 1.20319 10.4102 1 10.9217 1H16.7075C17.2189 1 17.7095 1.20319 18.0712 1.56487C18.4328 1.92654 18.636 2.41708 18.636 2.92857V17.0714C18.636 17.5829 18.4328 18.0735 18.0712 18.4351C17.7095 18.7968 17.2189 19 16.7075 19Z"
            stroke-width="0.3"/>
          <path
            d="M12.2062 13.8574C12.1216 13.8579 12.0377 13.8417 11.9594 13.8097C11.881 13.7777 11.8098 13.7306 11.7497 13.671C11.6895 13.6112 11.6416 13.5401 11.609 13.4618C11.5764 13.3835 11.5596 13.2994 11.5596 13.2146C11.5596 13.1297 11.5764 13.0457 11.609 12.9673C11.6416 12.889 11.6895 12.8179 11.7497 12.7581L14.514 10.0003L11.7497 7.24242C11.6898 7.18248 11.6422 7.11132 11.6098 7.03301C11.5774 6.95469 11.5607 6.87076 11.5607 6.78599C11.5607 6.6148 11.6287 6.45061 11.7497 6.32956C11.8708 6.20851 12.035 6.1405 12.2062 6.1405C12.2909 6.1405 12.3749 6.1572 12.4532 6.18964C12.5315 6.22208 12.6026 6.26962 12.6626 6.32956L15.8769 9.54385C15.9371 9.60361 15.9849 9.67471 16.0176 9.75305C16.0502 9.83139 16.067 9.91541 16.067 10.0003C16.067 10.0851 16.0502 10.1692 16.0176 10.2475C15.9849 10.3258 15.9371 10.3969 15.8769 10.4567L12.6626 13.671C12.6025 13.7306 12.5313 13.7777 12.453 13.8097C12.3746 13.8417 12.2908 13.8579 12.2062 13.8574Z"
            stroke-width="0.3"/>
          <path
            d="M15.4215 10.643H1.2786C1.1081 10.643 0.94459 10.5753 0.824031 10.4547C0.703472 10.3342 0.635742 10.1707 0.635742 10.0002C0.635742 9.82966 0.703472 9.66615 0.824031 9.54559C0.94459 9.42503 1.1081 9.3573 1.2786 9.3573H15.4215C15.592 9.3573 15.7555 9.42503 15.876 9.54559C15.9966 9.66615 16.0643 9.82966 16.0643 10.0002C16.0643 10.1707 15.9966 10.3342 15.876 10.4547C15.7555 10.5753 15.592 10.643 15.4215 10.643Z"
            stroke-width="0.3"/>
        </svg>
        <personal-account-header v-if="personalAccount" :data="dataPersonal" @tokenRemove="personalAccount = false" class="model_account"/>

      </div>

      <div class="header-nav-mobile" :class="{ 'index': isIndexPage }">
        <svg @click="isActiveMenu = true"  width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.620117" y="0.500977" width="20.0164" height="1.81967" rx="0.909836" fill="white"/>
          <rect x="0.620117" y="6.87012" width="20.0164" height="1.81967" rx="0.909836" fill="white"/>
          <rect x="0.620117" y="13.2383" width="20.0164" height="1.81967" rx="0.909836" fill="white"/>
        </svg>

        <div @click="isActiveMenu = true" >{{ $store.getters.GET_LANG ? 'Меню': 'Menu' }}</div>
        <v-modal-menu v-if="isActiveMenu" @click="isActiveMenu = false" @auth="isActiveAuth = true"/>
      </div>

    </div>
    <div v-show="!isIndexPage && isActiveSearch" class="main-search__layer"></div>
    <v-search v-show="!isIndexPage && isActiveSearch" @closeHeaderSearch="isActiveSearch = false" @click="isActiveSearch = false"></v-search>
    <div v-show="!!isActiveAuth" class="main-search__background">
      <v-auth @click="isActiveAuth = false"/>
    </div>
    <div class="alert_active" v-show="showAlert">Вы успешно вышли из личного кабинета<span @click="showAlert=false"></span></div>
  </header>
</template>

<script>
import lang from '@/mixins/lang'
import VSearch from '@/components/header/VSearch'
import VAuth from '@/components/header/VAuth'
import VModalMenu from '@/components/header/VModalMenu'
import PersonalAccountHeader from '@/components/header/PersonalAccountHeader'

export default {
  name: 'MainHeader',
  mixins: [lang],
  props: {
    isIndexPage: {
      // type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isActiveMenu: false,
      isActiveSearch: false,
      isActiveAuth: false,
      isActive: -1,
      personalAccount: false,
      showAlert: false
    }
  },
  mounted () {
    this.$store.dispatch('setAboutTheWard')
    this.$store.dispatch('getSections')
    this.dispatchDataUser()
    this.$store.dispatch('setEngFlag')

    const scriptBasic = document.createElement('script')
    scriptBasic.setAttribute('src', '/vueDev/jquery.js')
    scriptBasic.setAttribute('defer', 'defer')
    document.head.appendChild(scriptBasic)
    scriptBasic.addEventListener('load', (e) => {
      const script = document.createElement('script')
      script.setAttribute('src', '/vueDev/blindFull.min.js')
      script.setAttribute('defer', 'defer')
      document.head.appendChild(script)
    })
  },
  computed: {
    getSections () {
      return this.$store.getters.getSections
    },
    getAboutTheWard () {
      return this.$store.getters.getAboutTheWard
    },
    getEngFlag () {
      const flags = this.$store.getters.getEngFlag
      return flags.length && flags[0].is_active
    },
    loginActive () {
      return this.isActiveAuth
    },
    dataPersonal () {
      return this.$store.getters.getPersonalData
    },
    tokenGet () {
      return this.$store.getters.token
    }
  },
  watch: {
    loginActive () {
      this.dispatchDataUser()
    },
    tokenGet () {
      localStorage.getItem('token') ? this.showAlert = false : this.showAlert = true
      this.dispatchDataUser()
    }
  },
  methods: {
    dispatchDataUser () {
      if (localStorage.getItem('token')) {
        this.$store.dispatch('getPersonalData')
        this.personalAccount = true
      } else this.personalAccount = false
    },
    getMainPage (section) {
      let mainPage = ''
      let list = []

      list = section.pages.filter((page) => {
        if (page.is_main && !mainPage) {
          mainPage = page
        } else if (!page.is_main) {
          return true
        }
      })

      return {
        mainPage,
        list
      }
    }
  },
  components: {
    'v-search': VSearch,
    'v-modal-menu': VModalMenu,
    'v-auth': VAuth,
    PersonalAccountHeader
  }
}
</script>

<style scoped lang="scss">
.mt-logo {
  margin-top: -15px;
}
header {
  background: #FFFFFF;
  box-shadow: 0px 15px 25px rgba(189, 205, 221, 0.15);

  .header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 1rem 0;
    align-items: center;
    position: relative;
    z-index: 11;
    height: 90px !important;

    &-logo {
      width: 12.08rem;
      height: 3.489rem;
      object-fit: contain;
      object-position: left center;
    }

    &-nav {
      display: flex;
      flex: 1;
      align-items: center;
      padding: 0 3.75rem;

      a {
        font-weight: 600;
        font-size: .87rem;
        line-height: 114%;
        color: #003E78;
        mix-blend-mode: normal;

        margin-left: 2rem;
        text-transform: uppercase;

        &:last-child {
          margin-right: 0;
        }
      }
    }
     &-nav-mobile {
       position: relative;
        margin-left: 3rem;
        display: none;
        align-items: center;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 121%;
        text-transform: uppercase;

        mix-blend-mode: normal;
        svg {
            *{

          fill: #003E78;

          }
          margin-right: 0.5rem;
          height: 1.12rem;
        }

        nav {
          background: #F1F3F4;
          position: fixed;
          top: 0px;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: 2;
          display: flex;
          flex-direction: column;
        }
      }

    &-icons {
      display: flex;
      align-items: center;

      * {
        margin-right: 2rem;
        cursor: pointer;
        user-select: none;

        &:last-child {
          margin-right: 0;
        }
      }

      p {
        min-width: 1.45rem;
        font-size: 1.12rem;
        color: #5A79A8;
        mix-blend-mode: normal;

      }

      svg {
        height: 1.12rem;
      }

      .svg-simple {
        fill: #5A79A8;
      }

      .svg-stroke {
        fill: #5A79A8;
        stroke: #5A79A8
      }
    }
  }

  .index-page {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    padding: 0 !important;
    a, p {
      color: #fff;
    }

    svg {
      *{
        fill: #fff;
      }
    }
      .header-nav-mobile{
        color: white;
      }
  }

  .index {
    .svg-simple {
      fill: #fff;
    }

    .svg-stroke {
      fill: #fff;
      stroke: #fff
    }
  }
  .main-search__layer{
    background: #1F3245;
    opacity: 0.33;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
  }
  .main-search__background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex: 1;
    background: rgba(32, 51, 70, 0.33);
    z-index: 55;
  }
}
.header-nav .dropbtn {
    background: none;
    border: none;
    font-weight: 600;
    font-size: .87rem;
    line-height: 114%;
    color: #003E78;
    mix-blend-mode: normal;
    margin-left: 2rem;
    text-transform: uppercase;
    cursor: pointer;
    padding: 1rem 0;
}
.index-page .dropbtn{
  color: #fff;
  padding-bottom: 1rem;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.header-nav .dropdown-content {
    display: none;
    position: absolute;
    background-color: inherit;
    min-width: 15rem;
    background-color: #f1f1f1;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}
.index-page .dropdown-content,
.header-nav .dropdown-content{
  background-color: rgba(0,0,0,0);
  box-shadow: unset;

  position: absolute;
  right: 0;
  width: 10.5rem;

  background: #FFFFFF;
  filter: drop-shadow(0px 2.063rem 5.5rem rgba(37, 70, 103, 0.15));
  border-radius: .19rem;
  padding: .94rem 0;

  a{
      font-size: .81rem;
      line-height: 1rem;
      color: #003E78;
      cursor: pointer;
  }
}
.index-page .dropdown-content:before,
.header-nav .dropdown-content:before{
    content: '';
    position: absolute;
    top: -0.13rem;
    right: 1.4rem;
    width: 0.68rem;
    height: .41rem;

    background: #FFFFFF;
    transform: matrix(0.71, 0.42, -1.2, 0.71, 0, 0);
    filter: drop-shadow(0px 2.063rem 5.5rem rgba(37, 70, 103, 0.15));
}

.dropdown-content a {
    color: black;
    font-size: 0.75rem;
    padding: 0.85rem 0.75rem;
    line-height: 150%;
    text-decoration: none;
    display: block;
}

.dropdown:hover .dropdown-content {display: block;}

.alert_active{
  position: absolute;
  background: #AFC765;
  z-index: 999999;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  border-radius: .19rem;
  top: 2rem;
  right: 2rem;
  cursor: pointer;

  span{
    position: absolute;
    top: -.5rem;
    right: -.5rem;
    padding: .6rem;

    background: #ffffff url('../../assets/icons/close.svg') center center no-repeat;
    background-size: contain;
    border-radius: 50%;
  }
}
@media screen and (max-width: 768px){
  header {

    .header {

      &-logo {
        width: 8.1875rem;
        height: 2.5rem;
      }
      &-nav{
        display: none;
      }
      &-icons {
        margin-left: auto;
      }

      display: flex;
      justify-content: space-between;
      align-content: center;
      padding: .75rem 0;

      // &-nav {
      //   display: none;
      // }
      &-nav-mobile {
        display: flex;
        cursor: pointer;
      }
    }
    .index-page .header-nav-mobile {
      display: flex;
    }

    .header-nav a{
      width: 5rem;
    }
  }
}

@media screen and (max-width: 420px){
  header {

    .header {

      &-logo {
        width: 8.26rem;
        /*padding-right: .1rem;*/
      }

      display: flex;
      justify-content: space-between;
      align-content: center;
      padding: .75rem 0;

      &-nav, &-icons {
        display: none;
      }
    }

  }
  .model_account{
    display: none;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  header, header .header {
    height: 6rem !important;

    .header-icons {
      svg {
        width: 1.5rem;
      }

      * {
        fill: #5A79A8;
        stroke: #5A79A8;
      }
    }
  }

  .index-page-header {
    background: none !important;
    box-shadow: none !important;
  }

}

</style>
