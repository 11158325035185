<template>
  <div>
    <router-link class="come-back" to="/activities_projects"><div></div>{{ $store.getters.GET_LANG ? 'Вернуться в раздел “Текущие проекты”' : 'Go back' }}</router-link>
    <div class="two-rows-wrapper">
      <main>
        <div class="main-project">
          <!-- <p class="main-project__date">{{ currentTopic.dateTime }}</p> -->
          <article>
            <header>
              <h1>{{ currentTopic.title }}</h1>
            </header>
            <figure :style="`background: url('${currentTopic.photo}') center / cover`"></figure>
<!--            <div v-html="currentTopic.content" class="main-project__text ckEditor-html"></div>-->
          </article>

          <!-- <div v-if="currentTopic.description_1" class="main-project__document-header"> -->
           <!-- <h2>{{ $store.getters.GET_LANG ? 'Описание' : 'Description' }}</h2> -->
          <!-- </div> -->
          <div v-if="currentTopic.description_1" v-html="currentTopic.description_1" class="main-project__description ckEditor-html"></div>
          <div class="main-project__document-header">
           <h2 v-if="documentsType.length">{{ $store.getters.GET_LANG ? 'Документы' : 'Documents' }}</h2>
          </div>
          <ul class="documents__type-list">
            <li v-for="(type, key) of documentsType" class="documents__type-item"
                :class="{'documents__active' : isActiveItem === key}"
                :key="`type${key}`"
                @click="ActiveItem(type, key)">
              {{ type }}
            </li>
          </ul>
          <h2 v-if="documentsType.length" class="title-section" >{{ this.titleActive }}</h2>
          <div v-for="(item, i) in documents" :key="`documents${i}`">
            <documents-info :content="item" />
          </div>
          </div>
      </main>
      <similar-block-region
              :title="$store.getters.GET_LANG ? 'Новости проекта' : 'News project'"
              :getNews="getNews"
              :with-tag="getFormattedTitle()"
              with-system-tag="projects"
              v-if="getNews.length!==0"
              class="block_region"
              link="/news?tab=News"/>
    </div>
  </div>
</template>

<script>
import DocumentsInfo from '@/components/DocumentsInfo'
import SimilarBlockRegion from '@/components/activitiesSurveys/SimilarBlockRegion'
import uniq from '@/methods/uniq'

export default {
  name: 'DetailProject',
  data () {
    return {
      currentTopic: {},
      documents: [],
      documentsAll: [],
      documentsType: [],
      isActiveItem: -1,
      titleActive: 'Все'
    }
  },
  methods: {
    ActiveItem (name, i) {
      this.isActiveItem = i
      this.titleActive = name
      this.documents = []
      this.documentsAll.map(item => {
        if (item.tagList.includes(name)) {
          this.documents.push(item)
        }
      })
    },

    getFormattedTitle () {
      return this.currentTopic.title.replace(/#/, '')
    }
  },
  async mounted () {
    let res = await fetch(`${this.$store.getters.getUrlApi}api/projects/${this.$route.params.id}`)
    res = await res.json()
    res = await res.data
    this.currentTopic = {
      title: res.name,
      dateTime: new Date(res.date).toLocaleDateString('ru'),
      photo: `${this.$store.getters.getUrlApi}${res.previewPhoto}`,
      content: res.description,
      tags: res.tagList,
      description_1: res.description_1 ? res.description_1 : null
    }
    document.title = res.name
    const allTags = []
    await res.documentList.map(item => {
      item.tagList.map(tag => {
        allTags.push(tag)
      })
      this.documentsAll.push({
        ...item,
        date: new Date(item.createDate).toLocaleString('ru', { day: 'numeric', month: 'numeric', year: 'numeric' }),
        name: item.name,
        url: `${this.$store.getters.getUrlApi}${item.url}`,
        size: item.size,
        type: item.type
      })
    })
    this.documents = this.documentsAll
    this.documentsType = uniq(allTags)
  },
  computed: {
    getNews () {
      return this.$store.getters.getPublicNews ? this.$store.getters.getPublicNews.slice(0, 3) : []
    }
  },
  watch: {
    currentTopic () {
      this.$store.commit('setFilterTag', this.getFormattedTitle())
      this.$store.commit('setFilterSystemTagList', 'projects')
      this.$store.dispatch('getPublicNews')
      this.$store.commit('setFilterTag', null)
      this.$store.commit('setFilterSystemTagList', null)
    },
    documentsType (value) {
      if (value.length) {
        this.ActiveItem(this.documentsType[0], 0)
      }
    }
  },
  components: {
    DocumentsInfo,
    SimilarBlockRegion
  }
}

</script>

<style lang="scss" scoped>
.ckEditor-html {
  ::v-deep {
    * {
      margin: revert;
      padding: revert;
      list-style-type: revert;
      font-size: revert;
      font-weight: revert;
    }
    a {
      color: #0000ee !important;
      //font-weight: bold;
    }

    a:visited {
      //color: #ab00be !important;
    }
    iframe {
      width: 100%;
      aspect-ratio: 16/9;
    }
  }
}
.documents {
  &__type-list {
    margin-left: 1.375rem;
    margin-bottom: 3.125rem;
  }
  &__type-item {
    list-style: disc;
    margin-bottom: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #246CB7;

    cursor: pointer;
  }
  &__filter {
    margin-bottom: 1.875rem;
    display: inline-flex;
  }

  &__filter-select {
    width: 7.5rem;
  }

  &__filter-link {
    padding-left: 1.375em;
    display: flex;
    align-items: center;

    font-size: .75rem;
    line-height: 1.125rem;
    color: #003E78;
    opacity: 0.6;

    &::after {
      content: '';
      margin-left: 0.75rem;
      width: 0.7rem;
      height: 0.7rem;
      background-image: url('../../assets/icons/icon_link.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &__list {
    margin-bottom: 2.5rem;
  }
  &__active {
    font-weight: 600;
  }
}
.partner-info{
  margin-bottom: 2rem;
}
  ::v-deep main {
    background: none;
    padding: 0;
    border-radius: 0;
  }
  .title-section {
    font-weight: 600;
    font-size: 1rem;
    color: #1F3245;
    margin-bottom: 2rem;
  }
  .main-project {
    font-size: 0.875rem;
    background: white;
    min-height: 21rem;
    padding: 2.5rem 3.125rem;
    border-radius: 8px;

    &__date {
      color: #5E686C;
      opacity: 0.8;
      line-height: 1.57;
      margin-bottom: 1.25rem;
    }

    figure {
        border-radius: 8px;
        // width: 15rem;
        width: 50%;
        max-width: 20rem;
        height: 11.25rem;
        float: left;
        margin-right: 1.5625rem;
    }

    article {
      text-align: left;
      margin-bottom: 3.125rem;
    }

    &__text {
      min-height: 11em;
      :nth-child(n) {
        margin-bottom: 1.375rem;
        line-height: 157%;
        color: rgba(23, 23, 23, 0.8);
      }

    }
    &__description{
      min-height: 1.8rem;
      margin-bottom: 2rem;
      ::v-deep {
        table {
          border: 1px solid #eee;
          border-spacing: 0;

          td {
            border: none;
            border-bottom: 1px solid #eee;
            border-top: 1px solid #eee;
            padding: .5rem 0;
          }
        }
      }
    }

    &__document-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;

      h2 {
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.5rem;
        color: #1F3245;
      }
      a {
        font-size: 0.875rem;
        color: #246CB7;
      }
      svg {
        width: 0.8rem;
        height: 0.8rem;
      }
    }

  }

  .line_wrapper {
    width: 100%;
    margin: 0.75rem 0 1.375rem;
  }

  .last-news {

    &__title {
      font-size: 0.875rem;
      text-transform: uppercase;
      font-weight: 600;
    }
    &__list {
      // sw
    }
    &__content:not(:last-child) {
      border-bottom: 1px solid #D5D6D9;
      padding-bottom: 0.75rem;
      margin-bottom: 0.75rem;
    }
  }

  h1 {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1.27;
    color: #1F3245;
    margin-bottom: 2rem;
  }

  .two-rows-navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1.875rem 3.125rem 1.875rem 1.875rem;
  }

  @media screen and (max-width: 768px) {
    .come-back {
      width: 90%;
    }
    .last-news {
      display: none;
    }
    //.block_region{
    //  display: none;
    //}
    aside   {
      width: 100% !important;
      min-width: 25.125rem !important;
    }
  }

  @media screen and (max-width: 440px) {
    .main-project {
      width: 100%;
      margin: 0;
      padding: 1.88rem 1rem;

      figure{
          border-radius: 8px;
          width: 100%;
          height: 11.25rem;
          float: unset;
          margin-right: 0;
          margin-bottom: 1.375rem;
      }
    }
    aside   {
      display: block;
    }
  }

</style>
